@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Montserrat"),
    url("./Montserrat-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local("Montserrat"),
    url("./Montserrat-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("Montserrat"),
    url("./Montserrat-Bold.woff2") format("woff2");
}
