div.swiper {
  & > div.swiper-wrapper {
    padding-bottom: 20px;
  }

  & > div.swiper-pagination {
    bottom: 0;

    & > span.swiper-pagination-bullet-active {
      background: #f07c93;
    }
  }
}

table.MuiTable-root {
  min-width: 0;
}

div.MuiPaper-root {
  max-width: 100vw;
  @media screen and (max-width: 555px) {
    max-width: 78vw;
  }

  @media screen and (max-width: 450px) {
    max-width: 71vw;
  }

  @media screen and (max-width: 330px) {
    max-width: 66vw;
  }

  &.MuiAccordion-root {
    max-width: 100vw;
  }
}

/* scroll-to-top  */
button.scroll-to-top {
  right: 25px;
  bottom: 90px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.webwhiz-widget {
  right: 15px !important;
  bottom: 11px !important;
}
