html {
  scroll-behavior: smooth;
}

.title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.333;
  text-align: left;
  letter-spacing: -2px;

  @media screen and (max-width: 501px) {
    word-break: break-word;
  }
}

.desc {
  font-size: 24px;
  margin-bottom: 35px;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -1px;

  @media screen and (max-width: 501px) {
    font-size: 18px;
  }
}

ul.ul {
  list-style: disc;
  margin: 18px 0;
  padding-left: 40px;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
  }
}

ol.ol {
  list-style: decimal;
  margin: 18px 0;
  padding-left: 40px;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
  }

  & > li {
    margin: 20px 0;
  }
}

ul.blog-ul {
  list-style: disc;
  margin: 18px 0;
  padding-left: 40px;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
  }

  & > li {
    margin: 20px 0;
  }
}
